@font-face {
  font-family: bodoni;
  /* src: url("bauer_bodoni_d_demi_bold-webfont.woff"); */
  src: url("BauerBodD-DemiBold.woff2");
  /* fontfile stripped to contain only basic latin using FontForge */
  /* font-display: swap; */
}

.print {
  display: none;
}

.screen {
  display: initial;
}

@media print {
  .print {
    display: initial;
  }
  .screen {
    display: none;
  }
}

.header1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  /* letter-spacing: -.1rem; */
  margin: 0;
  font-family: bodoni;
  font-size: 2.1rem;
}

html {
  -webkit-text-size-adjust: 100%;
  background: gray;
  display: flex;
  justify-content: center;
  align-items: center; /* not doing its thing? */
}

.button {
  border-radius: 0.25rem;
  background: rgb(62, 135, 106);
  color: white;
  font-size: 1rem;
  border: none;
  font-weight: bold;
  padding: 0.5rem 1rem;
  margin-top: 0.2rem;
  margin: .25rem auto;
  display:block;
}

.button:hover {
  background: rgb(35, 109, 82);
}

.section-title {
  font-variant-caps: small-caps;
  font-weight: 600;
}

body {
  /* color: black;
  line-height: 1.2; */
  font-family: arial;
  font-size: 10.5pt;
  max-width: 8.5in;
  /* height: 11in; */
  background: white;
}

#content {
  padding: 1rem 2rem;
}

p,
ul {
  /* margin-top: .25rem;
  margin-bottom: .25rem; */
  margin-top: 0.05rem;
  margin-bottom: 0;
}

ul {
  padding-left: 1.2rem;
}

hr {
  border-color: black;
  border-width: 0.4px;
  margin: 0.1rem;
}

.trio {
  font-weight: 700;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: "column0 column1 column2";
}

#header {
  font-size: 0.8rem;
  padding: 1rem 2rem;
  background: rgb(55, 128, 172);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: "column0 column1 column2";
  gap: 1rem;
}

.column0,
.header0 {
  grid-area: column0;
}

.column1,
.header1 {
  grid-area: column1;
}

.column2,
.header2 {
  grid-area: column2;
  text-align: right;
}

@media screen and (max-width: 768px) {
  #header {
    grid-template-columns: auto auto;
    grid-template-areas:
      "column1 column1"
      "column0 column2";
  }
  .trio {
    grid-template-columns: auto auto;
    grid-template-areas:
      "column0 column2"
      "column1 column2";
  }
  h1 {
    line-height: 1;
  }
}

@media (max-width: 480px) {
  #header {
    padding: 2rem;
    grid-template-columns: auto;
    grid-template-areas:
      "column1"
      "column0"
      "column2";
  }
  .header0,
  .header1,
  .header2 {
    text-align: center;
  }
  #content {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.section {
  /* padding: .3rem .8rem */
  padding: 0 0.8rem;
}

.subsection {
  padding-bottom: 0.25rem;
}

a {
  color: black;
}
